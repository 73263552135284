import React, { useContext } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

import GlobalContext from '../../context/GlobalContext';
import { Section, Title, Button } from '../../components/Core';
import bgFooter from '../../assets/image/png/subscribe-pattern.png';
import asiakastieto from '../../assets/image/jpg/Lasi_Liukkonen.jpg';

const SectionStyled = styled(Section)`
  &::before {
    position: absolute;
    bottom: -150px;
    content: '';
    width: 120%;
    height: 150%;
    background: url(${bgFooter}) bottom center no-repeat;
    background-size: cover;
    z-index: -1;
  }
`;

const Hero = () => {
  const gContext = useContext(GlobalContext);

  return (
    <>
      <SectionStyled className="position-relative">
        <Container>
          <Row className="text-center justify-content-center">
            <Col lg="11" xl="8">
              <div className="text-center my-5">
                <Title>Kysy lisää tai pyydä tarjous.</Title>
              </div>
              <div className="text-center mb-5">
                <Button
                  onClick={e => {
                    e.preventDefault();
                    gContext.toggleContact();
                  }}
                >
                  Ota yhteyttä
                </Button>
              </div>
              <img src={asiakastieto} alt="Lasi Liukkonen Asiakastieto" className="w-200" />
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Hero;
